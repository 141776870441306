



































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ControlPanelMeetingList from "@/components/control-panel/meetings/ControlPanelMeetingList.vue";
import ControlPanelMeetingFileList from "@/components/control-panel/meetings/ControlPanelMeetingFileList.vue";
import moment from "moment";
import FormButton from "@/components/form/Button.vue";
import DateInput from "@/components/form/DateInput.vue";
import TextInput from "@/components/form/TextInput.vue";

@Component({
  components: {
    ControlPanelMeetingList,
    ControlPanelMeetingFileList,
    DateInput,
    TextInput,
    FormButton,
  },
})
export default class MeetingsControlPanel extends Vue {
  // New meeting
  public meetingName: string = "";
  public startDate: Date = new Date();
  public endDate: Date = null;
  public location: string = "";

  public meetingNameWarning = false;
  public startDateWarning = false;

  // Edit meeting
  public currentEditMeetingId: number = -1;
  public editMeetingName: string = "";
  public editStartDate: Date = new Date();
  public editEndDate: Date = null;
  public editLocation: string = "";

  public editMeetingNameWarning = false;
  public editStartDateWarning = false;

  // Meeting files
  public editMeetingFiles: any[] = [];
  public editMeetingFileName: string = "";

  public deleting: boolean = false;

  @Prop({ required: true })
  private meetingType: number;
  @Prop({ required: true })
  private pageTitle: number;

  public validateNew() {
    this.meetingNameWarning = !this.meetingName;
    this.startDateWarning = !moment(this.startDate).isValid();

    return !this.meetingNameWarning && !this.startDateWarning;
  }

  public addMeeting() {
    if (this.validateNew()) {
      this.$store
        .dispatch("meetings/addMeeting", {
          meetingType: this.meetingType,
          meeting: {
            title: this.meetingName,
            start_date: moment(this.startDate).format("YYYY-MM-DD"),
            end_date: moment(this.endDate).isValid()
              ? moment(this.endDate).format("YYYY-MM-DD")
              : null,
            location: this.location,
          },
        })
        .then(() => {
          this.meetingName = "";
          this.startDate = new Date();
          this.endDate = new Date();
          this.location = "";
          this.$modal.hide("add-meeting");
        });
    }
  }

  public editMeeting(meeting: any) {
    this.editMeetingName = meeting.title;
    this.editStartDate = meeting.start_date;
    this.editEndDate = meeting.end_date;
    this.editLocation = meeting.location;
    this.currentEditMeetingId = meeting.id;

    this.$modal.show("edit-meeting");
  }

  public editFiles(meeting: any) {
    this.editMeetingFiles = meeting.files;
    this.currentEditMeetingId = meeting.id;
    this.$modal.show("edit-meeting-files");
  }

  public validateEdit() {
    this.editMeetingNameWarning = !this.editMeetingName;
    this.editStartDateWarning = !moment(this.editStartDate).isValid();

    return !this.editMeetingNameWarning && !this.editStartDateWarning;
  }

  public submitEdit() {
    if (this.validateEdit()) {
      this.$store
        .dispatch("meetings/editMeeting", {
          meetingType: this.meetingType,
          meeting: {
            id: this.currentEditMeetingId,
            title: this.editMeetingName,
            start_date: moment(this.editStartDate).format("YYYY-MM-DD"),
            end_date: moment(this.editEndDate).isValid()
              ? moment(this.editEndDate).format("YYYY-MM-DD")
              : null,
            location: this.editLocation,
          },
        })
        .then(() => {
          this.editMeetingName = "";
          this.editStartDate = new Date();
          this.editEndDate = new Date();
          this.editLocation = "";
          this.currentEditMeetingId = -1;
          this.$modal.hide("edit-meeting");
        });
    }
  }

  public async deleteMeeting() {
    if (
      confirm(
        `Are you sure you want to delete this meeting? This will also delete related files. This action can not be undone.`,
      )
    ) {
      this.deleting = true;
      this.$store
        .dispatch("meetings/deleteMeeting", {
          meetingType: this.meetingType,
          meetingId: this.currentEditMeetingId,
        })
        .then(() => {
          this.editMeetingName = "";
          this.editStartDate = new Date();
          this.editEndDate = new Date();
          this.editLocation = "";
          this.currentEditMeetingId = -1;
          this.deleting = false;
          this.$modal.hide("edit-meeting");
        });
    }
  }

  public addFile() {
    const fileInput = this.$refs.meetingFileInput as HTMLInputElement;

    if (this.editMeetingFileName && fileInput.value) {
      const formData = new FormData();
      formData.append("title", this.editMeetingFileName);
      formData.append("file", fileInput.files[0]);
      formData.append("meetingId", this.currentEditMeetingId.toString());

      this.$store
        .dispatch("meetings/addFile", {
          meetingType: this.meetingType,
          meetingId: this.currentEditMeetingId,
          formData,
        })
        .then((file) => {
          this.editMeetingFileName = "";
          fileInput.value = "";
        });
    }
  }

  public async deleteFile(file: any) {
    if (confirm(`Delete file ${file.title}?`)) {
      await this.$store.dispatch("meetings/deleteFile", {
        meetingType: this.meetingType,
        file,
        meetingId: this.currentEditMeetingId,
      });
    }
  }
}
