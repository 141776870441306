















import { Component, Prop, Vue } from "vue-property-decorator";
import ControlPanelMeeting from "./ControlPanelMeeting.vue";

@Component({
  components: {
    ControlPanelMeeting,
  },
  props: {
    upcoming: { type: Boolean },
    board: { type: Boolean },
  },
})
export default class ControlPanelMeetingList extends Vue {
  @Prop()
  public upcoming!: boolean;

  @Prop()
  public board: boolean;

  @Prop({ required: true })
  public meetingType: number;

  public title = (this.upcoming ? "Upcoming " : "Past ") + "meetings";
  private key = this.upcoming ? "upcoming" : "past";

  public created() {
    if (this.board) {
      this.$store.dispatch("meetings/fetchMeetings", this.meetingType);
    } else {
      this.$store.dispatch("meetings/fetchMeetings", this.meetingType);
    }
  }

  public editMeeting(meeting: any) {
    this.$emit("editMeeting", meeting);
  }

  public editFiles(meeting: any) {
    this.$emit("editFiles", meeting);
  }

  get meetings() {
    return this.$store.state.meetings[this.meetingType][this.key];
  }
}
